import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { CustomValidators } from '@app/_validators/custom-validators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GroupType } from '../data-table.component';

@Component({
  selector: 'app-data-table-filter-dialog',
  templateUrl: './data-table-filter-dialog.component.html',
  styleUrls: ['./data-table-filter-dialog.component.scss'],
})
export class DataTableFilterDialogComponent implements OnInit {
  private ignoredGroups: GroupType[] = [];
  readonly _groupTypes: typeof GroupType = GroupType;
  get groupTypes() {
    return Object.keys(this._groupTypes).filter(
      (x) => this.ignoredGroups.findIndex((g) => g.toLowerCase() === x.toLowerCase()) === -1,
    );
  }
  group: UntypedFormGroup;
  constructor(
    private ref: MatDialogRef<DataTableFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: Partial<DataTableFilterData>,
    private fm: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.ref.addPanelClass(['mat-dialog-relative']);
    this.ref.updateSize('480px');
    const fm = this.fm;
    this.group = fm.group({
      selectedGroup: fm.control(this.data?.group || this.groupTypes[0]),
    });
    this.group.setValidators([CustomValidators.mustChange(this.group.value)]);
    this.ignoredGroups = this.data?.ignoredGroups || [];

    this.group.updateValueAndValidity();
  }

  groupTypeComparer(left: any, right: any) {
    return left == right || left == GroupType[right] || right == GroupType[left] || GroupType[left] == GroupType[right];
  }
  applyGroup(grp: string) {
    this.group.controls.selectedGroup.setValue(GroupType[grp] || GroupType[this.groupTypes[0]]);
  }
  applyFilters() {
    return this.ref.close(<DataTableFilterData>{
      group: this.group.value.selectedGroup,
    });
  }
}
export interface DataTableFilterData {
  group: GroupType;
  ignoredGroups: GroupType[];
}
