import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import RoundingConfig from '@app/shared/dialogs/rounding-dialog/models/rounding-config';
import { RoundingDialogComponent, RoundingTypes } from '@app/shared/dialogs/rounding-dialog/rounding-dialog.component';
import {
  nearestFutureMinutes,
  nearestMinutes,
  nearestPastMinutes,
} from '@app/shared/dialogs/rounding-dialog/rounding-functions';
import { TimeTrackerCalendarUpdateDialogComponent } from '@app/shared/time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';
import { UtilService } from '@app/_services/util.service';
import { CalendarEvent } from 'calendar-utils';
import { chain, sumBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ClientsQuery, Logger, MyTimesService, ProjectsQuery, Time, UserSettingsQuery } from 'timeghost-api';
import {
  DataTableFilterData,
  DataTableFilterDialogComponent,
} from './data-table-filter-dialog/data-table-filter-dialog.component';
export enum GroupType {
  Project = 'project',
  User = 'user',
  Client = 'client',
  Task = 'task',
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  @Input()
  ignoreGroups: string[] = [];
  readonly groupTypes: typeof GroupType = GroupType;
  private selectedGroup = new BehaviorSubject<GroupType>(GroupType[this.allowedGroups[0]]);
  get group(): GroupType {
    return this.selectedGroup.getValue();
  }
  get allowedGroups() {
    return Object.keys(this.groupTypes).filter(
      (x) => this.ignoreGroups.findIndex((g) => g.toLowerCase() === x.toLowerCase()) === -1,
    );
  }
  @Output()
  readonly groupChange = new EventEmitter(true);
  @Input()
  set group(val: GroupType) {
    this.selectedGroup.next(val);
    this.groupChange.emit(val);
  }
  @Output()
  readonly group$ = this.selectedGroup.asObservable().pipe(
    startWith(this.group),
    filter((x) => x !== undefined),
    distinctUntilChanged(),
  );
  readonly group$name = this.group$.pipe(map((x) => GroupType[x]));
  @Input()
  currencyCode: string;

  @Input()
  showParentData: GroupType[] = null;

  @Input()
  showRoundingButton: boolean = true;

  hasShowParentFlag(type: GroupType) {
    if (!this.showParentData) return true;
    return this.showParentData.includes(type);
  }

  private entries = new BehaviorSubject<any[]>([]);
  readonly entries$ = combineLatest([
    this.group$.pipe(startWith(GroupType[this.allowedGroups[0]])),
    this.entries.asObservable(),
    this.appService.roundingData$.pipe(startWith(this.appService.roundingData)),
  ]).pipe(
    filter(([grp, entries]) => !!grp && !!entries),
    distinctUntilChanged(),
    map(([grp, x, rounding]) => {
      if (x.filter((y) => y.cosmosEntityName === 'times').length > 0) {
        x = chain(x)
          .orderBy((y: Time) => new Date(y.start))
          .groupBy((y: Time) => {
            // @ts-ignore
            if (grp === GroupType.Client && y.client) {
              // @ts-ignore
              return y.client.id;
            }
            if (grp === GroupType.Project && !!y.project.id) {
              return y.project.id;
            }
            if (grp === GroupType.User && !!y.user.id) {
              return y.user.id;
            }
            if (grp === GroupType.Task && !!y.task?.id) {
              return y.task.id;
            }
            return null;
          })
          .toPairs()
          .value()
          .map(([key, value]: [any, Time[]]) => {
            const data = value.length > 0 ? value[0] : null;
            return {
              key,
              data,
              // @ts-ignore
              type: value.length > 0 ? value[0].cosmosEntityName : null,
              group: grp,
              sum: () => {
                let sum = value.length > 0 ? sumBy(value, (s) => s.timeDiff) * 1000 || 0 : 0;
                if (rounding?.enabled === true) {
                  if (rounding.type === RoundingTypes.Nearest) {
                    sum = nearestMinutes(rounding.minutes, sum) * 60 * 1000;
                  }
                  if (rounding.type === RoundingTypes.UpTo) {
                    sum = nearestFutureMinutes(rounding.minutes, sum) * 60 * 1000;
                  }
                  if (rounding.type === RoundingTypes.DownTo) {
                    sum = nearestPastMinutes(rounding.minutes, sum) * 60 * 1000;
                  }
                }
                return UtilService.parseMS(sum, {
                  showSeconds: false,
                });
              },
              value,
            };
          });
      }
      return x;
    }),
  );
  @Input()
  showColors: boolean = false;
  @Input()
  set data(val: any) {
    this.entries.next(val);
  }
  constructor(
    private dialog: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
    private appService: AppService,
    private clientQuery: ClientsQuery,
    private projectQuery: ProjectsQuery,
    private timeService: MyTimesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  isDefaultClient(id: string) {
    return this.clientQuery.getEntity(id)?.useAsDefault === true;
  }
  isDefaultProject(id: string) {
    return this.projectQuery.getEntity(id)?.useAsDefault === true;
  }
  getEntityColor(id: string) {
    const prefix = this.selectedGroup.getValue()?.toString().toLowerCase();
    if (prefix) id = prefix + '.' + id;
    return this.appService.getColorById(id);
  }
  getProjectColor(id: string) {
    return this.projectQuery.getEntity(id)?.color;
  }
  get HFormat() {
    return this.appService.timeFormat;
  }
  roundingData$ = this.appService.roundingData$;
  get roundingData() {
    return this.appService.roundingData;
  }
  get hasRounding() {
    return this.roundingData?.enabled === true;
  }
  openRoundingDialog() {
    this.dialog
      .open(RoundingDialogComponent, {
        data: new RoundingConfig({
          data: this.roundingData,
        }),
      })
      .afterClosed()
      .pipe(
        switchMap(async (x) => {
          if (!!x) {
            await this.appService.updateRounding(x);
          }
          log.debug(x);
          return x;
        }),
      );
  }
  applyGroup(grp: string) {
    this.group = GroupType[grp] || GroupType[this.allowedGroups[0]];
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  ngOnInit() {}
  trackItem(index: number, item: any) {
    return item.key || index;
  }
  trackItemEntry(index: number, item: Time) {
    return item.id || index;
  }
  groupTypeComparer(left: any, right: any) {
    return left == right || left == GroupType[right] || right == GroupType[left] || GroupType[left] == GroupType[right];
  }
  openFilterDialog() {
    return this.dialog
      .open(DataTableFilterDialogComponent, {
        data: <Partial<DataTableFilterData>>{
          group: this.group,
          ignoredGroups: this.ignoreGroups,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        if (x.group) this.group = x.group;
      });
  }

  timeContextMenuPosition = { x: '0px', y: '0px' };
  openTimeContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  isTimeOwner(id: string) {
    return this.userSettingsQuery.getValue().id === id;
  }
  @Output()
  deletedTime = new EventEmitter(true);
  @Output()
  updatedTime = new EventEmitter(true);
  deleteTime(id: string) {
    const time = this.entries.getValue()?.find((x: Time) => x.id === id);
    if (!time) return;
    return this.timeService
      .delete(time)
      .toPromise()
      .then(([x]: [Time]) => {
        if (x) this.deletedTime.emit(x);
        return x;
      });
  }
  editTime(id: string) {
    const time: Time = this.entries.getValue()?.find((x: Time) => x.id === id);
    if (!time) return;
    return this.dialog
      .open(TimeTrackerCalendarUpdateDialogComponent, {
        data: <CalendarEvent>{
          title: time.name,
          start: new Date(time.start),
          end: new Date(time.end),
          meta: { time },
        },
      })
      .afterClosed()
      .subscribe(([t]: [Time]) => {
        if (t) this.updatedTime.emit(t);
      });
  }
  @Output()
  namePress = new EventEmitter();
  onNameClick(group: GroupType, entity: Time) {
    return this.namePress.emit({
      group,
      entity,
    });
  }
}
const log = new Logger(DataTableComponent.name);
