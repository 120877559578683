export function nearestMinutes(interval: number, ms: number) {
  const val = ms / 1000 / 60;
  const roundedMinutes = Math.round(val / interval) * interval;
  return roundedMinutes % interval !== 0 ? val : roundedMinutes;
}
export function nearestPastMinutes(interval: number, ms: number) {
  const val = ms / 1000 / 60;
  const roundedMinutes = Math.floor(val / interval) * interval;
  return roundedMinutes % interval !== 0 ? val : roundedMinutes;
}
export function nearestFutureMinutes(interval: number, ms: number) {
  const val = ms / 1000 / 60;
  const roundedMinutes = Math.ceil(val / interval) * interval;
  return roundedMinutes % interval !== 0 ? val : roundedMinutes;
}
