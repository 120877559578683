<div matDialogTitle class="items-center justify-start">
  <span>{{ 'utils.sort' | translate }}</span>
  <div class="flex-auto"></div>
  <button mat-icon-button color="dark" matDialogClose tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-dialog-flex-content flex flex-col gap-2">
  <mat-form-field appearance="fill" class="mat-form-field-no-hint">
    <mat-label translate>utils.groupBy</mat-label>
    <mat-select
      [value]="this.group.value.selectedGroup"
      (selectionChange)="this.applyGroup($event.value)"
      [compareWith]="groupTypeComparer"
    >
      <ng-container>
        <mat-option *ngFor="let group of groupTypes; let i = index" [value]="group">
          {{ group | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-4">
  <button mat-raised-button color="primary" (click)="applyFilters()" [disabled]="this.group.invalid">
    <span translate>utils.apply-changes</span>
  </button>
</mat-dialog-actions>
