<mat-card class="data-table-card">
  <mat-card-header class="data-table-card-header flex flex-row items-center justify-start gap-2">
    <mat-form-field appearance="fill" class="mat-form-field-no-hint">
      <mat-label translate>utils.groupBy</mat-label>
      <mat-select
        [value]="this.group$ | async"
        (selectionChange)="this.applyGroup($event.value)"
        [compareWith]="groupTypeComparer"
      >
        <ng-container>
          <mat-option *ngFor="let group of allowedGroups; let i = index" [value]="group">
            {{ group | translate }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="flex-auto"></div>
    <div class="flex flex-row items-center justify-start gap-2">
      <button
        mat-icon-button
        (click)="this.openRoundingDialog()"
        [class.mat-hint]="!this.hasRounding"
        *ngIf="showRoundingButton"
      >
        <mat-icon>access_time</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col justify-stretch gap-3 pb-3">
      <ng-container *ngIf="this.entries$ | async as entries">
        <ng-container *ngIf="entries.length > 0; else noEntries">
          <mat-accordion
            #panelList
            class="mat-accordion-cards mat-elevation-z0"
            [displayMode]="'flat'"
            [multi]="false"
            [hideToggle]="true"
          >
            <ng-container *ngFor="let item of entries; let lastHeader = last; trackBy: trackItem">
              <ng-container *ngIf="item.value.length > 0">
                <mat-expansion-panel
                  #panel
                  class="mat-elevation-z0 mat-expansion-card"
                  [class.brd-b]="!lastHeader"
                  [hideToggle]="true"
                >
                  <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="56px">
                    <div fxFill class="flex flex-row items-center justify-start gap-[10px]">
                      <div
                        class="data-color-column"
                        *ngIf="showColors"
                        [style.backgroundColor]="
                          item.group === this.groupTypes.Project
                            ? this.getProjectColor(item.data.project.id) || this.getEntityColor(item.key)
                            : this.getEntityColor(item.key)
                        "
                      ></div>
                      <div class="badge badge-stroked" [style.width.px]="40" *ngIf="!panel.expanded">
                        {{ item.value.length > 9 ? '9+' : item.value.length }}
                      </div>
                      <div class="data-title-column flex flex-col">
                        <ng-container *ngIf="item.group === this.groupTypes.Client">
                          <span class="mat-truncate">{{
                            item.data.client | defaultEntityTranslate: 'client' : 'client.none'
                          }}</span>
                        </ng-container>
                        <ng-container *ngIf="item.group === this.groupTypes.Task">
                          <span class="mat-truncate">{{ item.data.task?.name || ('task.none' | translate) }}</span>
                        </ng-container>
                        <ng-container *ngIf="item.group === this.groupTypes.Project">
                          <span class="mat-truncate">{{
                            item.data.project | defaultEntityTranslate: 'project' : 'project.none'
                          }}</span>
                        </ng-container>
                        <ng-container *ngIf="item.group === this.groupTypes.User">
                          <span class="mat-truncate">{{ item.data.user.name }}</span>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            this.hasShowParentFlag(this.groupTypes.Task) && [this.groupTypes.Task].includes(item.group)
                          "
                        >
                          <div class="flex flex-row items-center justify-start gap-1">
                            <small class="mat-hint text-wrap-line data-short-name text-ellipsis">{{
                              item.data.client | defaultEntityTranslate: 'client' : 'client.none'
                            }}</small>
                            <small>/</small>
                            <small class="mat-hint text-wrap-line data-short-name text-ellipsis">{{
                              item.data.project | defaultEntityTranslate: 'project' : 'project.none'
                            }}</small>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            this.hasShowParentFlag(this.groupTypes.Project) &&
                            [this.groupTypes.Project].includes(item.group)
                          "
                        >
                          <div class="flex flex-row items-center justify-start gap-1">
                            <small class="mat-hint mat-truncate">{{
                              item.data.client | defaultEntityTranslate: 'client' : 'client.none'
                            }}</small>
                          </div>
                        </ng-container>
                      </div>
                      <div class="badge badge-stroked !ml-auto">
                        {{ item.sum() }}
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <cdk-virtual-scroll-viewport
                      [itemSize]="48"
                      [style.height.px]="item.value.length > 10 ? 600 : (item.value.length * 48).clampThis(100)"
                      class="data-row-container flex flex-col"
                    >
                      <ng-container
                        *cdkVirtualFor="
                          let entry of item.value;
                          let last = last;
                          let index = index;
                          trackBy: trackItemEntry
                        "
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            timesItem;
                            context: {
                              item: entry,
                              last: last,
                              index: index,
                              group: item.group,
                            }
                          "
                        >
                        </ng-container>
                      </ng-container>
                    </cdk-virtual-scroll-viewport>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>
          </mat-accordion>
        </ng-container>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #timesItem let-group="group" let-item="item" let-last="last" let-index="index">
  <div
    class="data-row flex flex-row items-center justify-start gap-2"
    (contextmenu)="
      openTimeContextMenu($event, timeContextMenuTrigger, {
        time: item,
        timeOwner: this.isTimeOwner(item.user?.id),
      })
    "
  >
    <div class="flex flex-col truncate">
      <div class="flex cursor-pointer items-center space-x-1 truncate" (click)="onNameClick(group, item)">
        <ng-container *ngIf="group === 'task'; else nonTaskName">
          <span class="flex-shrink-0 truncate">{{ item.name || ('time.none' | translate) }}</span>
        </ng-container>
        <ng-template #nonTaskName>
          <span class="flex-shrink-0 truncate">{{ item.name || ('time.none' | translate) }}</span>
          <div class="mat-bg-30 h-1 w-1 flex-shrink-0 rounded-full"></div>
          <span class="mat-hint truncate">{{ item.task?.name || ('task.none' | translate) }}</span>
        </ng-template>
      </div>
      <ng-container *ngIf="this.hasShowParentFlag(this.groupTypes.User) && [this.groupTypes.User].includes(group)">
        <div class="flex flex-row items-center justify-start gap-1 text-sm">
          <span class="mat-hint mat-truncate">{{
            item.client | defaultEntityTranslate: 'client' : 'client.none'
          }}</span>
          <span>/</span>
          <span class="mat-hint mat-truncate">{{
            item.project | defaultEntityTranslate: 'project' : 'project.none'
          }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="this.hasShowParentFlag(this.groupTypes.Client) && [this.groupTypes.Client].includes(group)">
        <div class="flex flex-row items-center justify-start gap-1 text-sm">
          <span class="mat-hint mat-truncate">{{
            item.project | defaultEntityTranslate: 'project' : 'project.none'
          }}</span>
          <ng-container *ngIf="item.user?.name as username">
            <div class="mat-bg-80 rounded-circle h-1 w-1"></div>
            <span class="mat-hint mat-truncate">{{ username }}</span>
          </ng-container>
        </div>
      </ng-container>
      <ng-container
        *ngIf="[this.groupTypes.Project, this.groupTypes.Task].includes(group) && item.user?.name as username"
      >
        <div class="flex flex-row items-center justify-start gap-1 text-sm">
          <span class="mat-hint mat-truncate">{{ username }}</span>
        </div>
      </ng-container>
    </div>
    <div class="flex-auto"></div>
    <div class="flex flex-row items-center justify-start gap-2 wflex-[0_0_auto]">
      <ng-container *ngIf="item.billable && this.currencyCode">
        <mat-icon inline color="accent">{{ this.currencyCode }}</mat-icon>
        <mat-divider vertical style="width: 1px; height: 8px"></mat-divider>
      </ng-container>
      <span>{{ item.timeDiff | parseMsAsDuration: false }}</span>
      <mat-divider vertical style="width: 1px; height: 10px"></mat-divider>
      <div class="data-time-column flex flex-col gap-1 wflex-[0_0_auto]">
        <div aria-multiselectable class="line-height-base flex flex-row items-center justify-start gap-1">
          <small>{{ item['viewStart'] ?? item.start | dfnsParseIso | formatDate: 'time' }}</small>
          <small>-</small>
          <small>{{ item['viewEnd'] ?? item.end | dfnsParseIso | formatDate: 'time' }}</small>
        </div>
        <small class="line-height-base">{{ item['viewStart'] ?? item.start | dfnsParseIso | formatDate: 'P' }}</small>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #noEntries>
  <div class="mat-hint py-10 text-center">{{ 'errors.empty' | translate }}</div>
</ng-template>
<mat-menu #headerSettings="matMenu">
  <ng-template matMenuContent>
    <h4 class="mat-typography mat-header-cell mx-2 mb-1 mt-1">Settings</h4>
    <button
      [disabled]="!this.showRoundingButton"
      mat-menu-item
      (click)="this.openRoundingDialog()"
      [matBadge]="' '"
      [matBadgeHidden]="!this.hasRounding"
    >
      <mat-icon>history</mat-icon> {{ 'Rounding' | translate }}
    </button>
  </ng-template>
</mat-menu>
<div
  #timeContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="timeContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="timeContextMenuPosition.x"
  [style.top]="timeContextMenuPosition.y"
></div>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-time="time" let-isOwner="timeOwner">
    <div class="flex flex-col">
      <button mat-menu-item (click)="this.editTime(time.id)" [disabled]="!time.currentUserCanEdit" translate>
        utils.edit
      </button>
      <div class="mt-1">
        <mat-divider inset></mat-divider>
      </div>
      <button
        mat-menu-item
        (click)="this.deleteTime(time.id)"
        class="text-warn"
        [disabled]="!time.currentUserCanEdit"
        translate
      >
        utils.delete
      </button>
    </div>
  </ng-template>
</mat-menu>
